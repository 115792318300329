import { combineReducers } from '@reduxjs/toolkit';

import rolesReducer from './reducers/role';

const rootReducer = combineReducers({
  role: rolesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
