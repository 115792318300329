import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Role {
    title: string;
    summary: string;
    organisation: string;
    organisation_id: number;
    video_url: string|null;
    slug: string;
    location: string;
    job_link: string;
    country: string;
    country_code: string;
    salary?: Salary;
    currency?: Currency;
    company_domain?: string;
    work_arrangement?: string;
    job_type?: string;
    description?: RoleDescription;
    skills?: RoleRequirements;
    created_at: string;
    posted_at?: string;
    mapped_job_title?: string;
    is_alooba_job: boolean;
}

export interface Salary {
  min: number;
  max: number;
  type: SalaryType;
}

export interface Currency {
  code: string|null;
  symbol: string|null;
}

export type SalaryType = 'YEARLY' | 'MONTHLY' | 'WEEKLY' | 'DAILY' | 'HOURLY';
export interface Location {
    city: string;
    country: string;
    description: string;
}

export interface RoleDescription {
    introduction?: string;
    responsibilities?: string;
    requirements?: RoleRequirements;
    benefits?: string;
    additionalInformation?: string;
}

export interface RoleRequirements {
    required?: string[];
    desirable?: string[];
}

export interface Pagination {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}
interface RolesState {
  roles: Role[];
  loading: boolean;
  pagination: Pagination;
  activeRole: Role|null;
}

const initialState: RolesState = {
  roles: [],
  loading: false,
  pagination: {
    current_page: 0,
    last_page: 1,
    per_page: 10,
    total: 1,
  },
  activeRole: null,
};


const rolesReducer = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    getRolesStart: (state) => {
      state.loading = true;
    },
    getRolesSuccess: (state, action: PayloadAction<{roles: Role[], page: Pagination}>) => {
      state.roles = action.payload.roles;
      state.pagination = action.payload.page;
      state.loading = false;
    },
    setActiveRole: (state, action: PayloadAction<Role>) => {
      state.activeRole = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  },
});
export const {
  getRolesStart,
  getRolesSuccess,
  setActiveRole,
  setLoading,
} = rolesReducer.actions;
export default rolesReducer.reducer;
